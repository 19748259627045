<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/receiveNotice' }">消息通知</el-breadcrumb-item>
          <el-breadcrumb-item>通知详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" ref="ruleForm" label-width="100px">
          <div class="form-title">基本信息</div>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则名称：">{{form.title}}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则内容：" prop="content">{{form.content}}</el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="规则类型：" prop="noticeTypeCode">
                {{form.noticeTypeCode=='equipment'?'设备类型':'-'}}
              </el-form-item>
            </el-col>
          </el-row>
          
          <!-- <el-row :gutter="20">
            <el-col :span="20">
              <el-form-item label="告警级别：">
                <el-radio-group v-model="form.level" style="width:100%;">
                  <el-radio :label="1" disabled>提示告警</el-radio>
                  <el-radio :label="2" disabled>普通告警</el-radio>
                  <el-radio :label="3" disabled>重要告警</el-radio>
                  <el-radio :label="4" disabled>严重告警</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row> -->

          <!-- 通知频次 -->
          <el-row :gutter="20" v-if="form.noticeTypeCode=='equipment'">
            <el-col :span="12">
              <el-form-item label="通知频次：" style="position:relative;width: 600px;">
                {{form.frequency.frequencyType=='every-hour'?'每小时'
                :form.frequency.frequencyType=='daily'?'每日'
                :form.frequency.frequencyType=='weekly'?'每周'
                :form.frequency.frequencyType=='monthly'?'每月'
                :'-'}}
                <el-tooltip class="item" effect="dark" :content="computedContent(form.frequency.frequencyType)" placement="top">
                  <i class="el-icon-info info-tips"></i>
                </el-tooltip>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 频次时间 -->
          <el-row :gutter="20" v-if="form.frequency.frequencyType&&form.frequency.frequencyType!='every-hour'">
            <el-col :span="12">
              <el-form-item label="频次时间：">
                {{form.frequency.dateTime?secondToDate(form.frequency.dateTime):'-'}}
              </el-form-item>
            </el-col>
          </el-row>

          <!-- 统计方式 -->
          <el-row :gutter="20" v-if="form.noticeTypeCode=='equipment'">
            <el-col :span="12">
              <el-form-item label="统计方式：">
                {{form.dataStatistics.statisticsType=='latest'?'实时'
                :form.dataStatistics.statisticsType=='daily'?'单日'
                :form.dataStatistics.statisticsType=='weekly'?'每周'
                :form.dataStatistics.statisticsType=='monthly'?'每月'
                :'-'}}
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="通知人：">
                <span v-if="form.receivers.length>0">
                  <span v-for="(item, index) in form.receivers" :key="index">
                    {{ form.receivers.length-1 > index ? item.staffName+'、' : item.staffName }}
                  </span>
                </span>
                <span v-else>-</span>
              </el-form-item>
            </el-col>
          </el-row> -->

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="通知方式：">
                <div class="notify-method">
                  <el-checkbox-group v-model="form.notificationModeCodes">
                    <!-- <el-checkbox label="system" disabled>系统内通知</el-checkbox> -->
                    <el-checkbox label="entWeChatRobot" disabled>企业微信</el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-form-item>
            </el-col>
          </el-row>

          <div v-if="form.notificationModeCodes">
            <el-row :gutter="20" v-if="form.notificationModeCodes.find(item=>item=='entWeChatRobot')=='entWeChatRobot'">
              <el-col :span="12">
                <el-form-item label="机器人：">
                  <span>{{ form.entWeChatRobotName?form.entWeChatRobotName:'-' }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          
          <div v-if="form.noticeTypeCode=='equipment'&&form.equipmentTrigger">
            <!-- 设备类型 -->
            <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
              <div class="form-title" style="margin-bottom:0;">设备类型</div>
              <div></div>
            </div>
            <!-- 循环设备Tag -->
            <div class="custom-attr" v-for="(dItem,dIndex) in form.equipmentTrigger.equipments" :key="dIndex">
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="设备名称：">{{ dItem.equipmentName }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="点表名称：">{{ dItem.triggerCondition.realData.tag }}</el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="点表描述：">{{ dItem.describe?dItem.describe:'-' }}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="单位：">{{ dItem.unit?dItem.unit:'-' }}</el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="数据类型：">{{dItem.triggerCondition.dataType}}</el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="点表值类型：" v-if="dItem.triggerCondition.dataType=='Real'">
                    {{dItem.triggerCondition.realData.formulaType?dItem.triggerCondition.realData.formulaType:'-'}}
                  </el-form-item>
                </el-col>
              </el-row>
              <div v-if="dItem.triggerCondition.dataType=='Bool'"></div>
              <div v-if="dItem.triggerCondition.dataType=='Real'">
                <div v-if="dItem.triggerCondition.realData.formulaType=='formula'">
                  <div class="formula-title">
                    <div class="formula-title-left">配置公式</div>
                    <div class="formula-title-right"></div>
                  </div>
                  <el-row :gutter="20" style="margin: 30px 0px 0px 30px;">
                    <el-col :span="12">
                      <el-form-item label="公式：" label-width="60px">
                        {{ dItem.triggerCondition.realData.formula?dItem.triggerCondition.realData.formula:'-' }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/receiveNotice')">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        frequency: {
          frequencyType:null,
          dateTime:null,
        },
        dataStatistics: {
          statisticsType:null
        },
        receivers: []
      },
      row: {},
      disabled: false,
      allFileList: [],
      tableData: [],
    };
  },
  computed: {
    computedContent() {
      return function(e){
        if(!e) return '-'
        if(e=='every-hour') return '通知频次为: 按小时为单位自动发送通知'
        if(e=='daily') return '通知频次为: 按天为单位自动发送通知'
        if(e=='weekly') return '通知频次为: 按周的星期一自动发送通知'
        if(e=='monthly') return '通知频次为: 按月的1号自动发送通知'
      }
    }
  },
  methods: {
    loadData() {
      this.loading = true
      this.$ajax.post('timeNoticeDetail',{},{},this.row.timerNoticeRuleId).then(res => {
        this.form = res.data
        this.loading = false
      }).catch(err=>{
        this.loading = false
      })
    },
    secondToDate(val) {
      let s = val % 60
      let _s = val - s

      let m = (_s / 60) % 60

      let h = (_s / 60 - m) / 60
      return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}`
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
      this.$router.push("/receiveNotice");
    } else {
      this.row = this.$route.params.row
      this.loadData()
    }
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 1200px;
}
.el-input {
  width: 500px;
}
.el-textarea {
  width: 500px;
}
.el-radio {
  margin-right: 60px;
}
.notify-method {
  width: 650px;
  display: flex;
}
.custom-attr {
  border: 1px solid #ebeef5;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
}
.formula-type {
  display: flex;
  align-items: center;
}
.formula-left {
  background-color: #ecf5ff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  color: #409eff;
  height: 30px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 14px;
}
.f-symbol {
  font-size: 14px;
  text-align: center;
  background: #f2f2f2;
  border-radius: 5px;
  width: 30px;
  line-height: 30px;
  margin: 0 20px;
  color: #333;
}
.formula-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .formula-title-left::before {
        content: '';
        border-left: 4px solid #409EFF;
        padding-left: 10px;
    }
}
.info-tips {
  font-size: 16px;
  color: #9a9a9a;
  transition: all .3s ease;
  &:hover {
    color: #c1c1c1;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; 
  background-color: #c1c1c1;
  /* #58585B */
}
</style>